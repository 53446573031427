/**
 * Created by rastwe on 1/26/2017.
 */
import envConfig from '@ey/voltron-config';
import * as _application_insights_ from '@microsoft/applicationinsights-react-js';

class Env extends Object {
	/**
	 * Returns true if the environment is production.
	 * @return {boolean} is production
	 */
	static isProd() {
		const env = __NODE_ENV || 'development';
		return env !== 'development';
	}

	/**
	 * Returns the current environment's server URL, appended with
	 * whatever path is passed in as an argument, or just the root
	 * serverURL if path is undefined.
	 * Notice that I'm requiring that all paths be preceeded by a "/"
	 * character. This is because you want any request to the API
	 * root to look like "/", instead of "".
	 * @param {String} path - Path to be appended to the server URL
	 * @return {String} complete path string
	 */
	static getServerUrl(path = '') {
		return `${envConfig.url.serviceUrl}${path}`;
	}

	static getURL(urlName) {
		return envConfig.url[urlName];
	}
	static getPaansConfiguration() {
		let paansConfig = envConfig.PaansConfiguration;

		if (paansConfig && paansConfig?.paansBaseUrl && paansConfig?.paansAppId && paansConfig?.paansRegion ) {
			//You need to add language and policy to the url.
			paansConfig.paansUrl = `${paansConfig.paansBaseUrl}/viewnotice?a=${paansConfig.paansAppId}&r=${paansConfig.paansRegion}`;
		} 

		return paansConfig;
	}	

	static getADProp(prop) {
		return envConfig.ad[prop];
	}

	/**
	 * Get the max length allowed for the body description
	 * @return {Number} the max length defined in the package file
	 */
	static getBodyMaxLength() {
		return envConfig.bodyMaxLength;
	}

	/**
	 * Get the Language from the package.json file
	 * @return {String} the language defined in the package file
	 */
	static getLanguage() {
		return envConfig.language;
	}
	static getInstrumentationKey() {
		return typeof _application_insights_ === 'undefined'
			? null
			: _application_insights_.instrumentationkey;
	}
	/**
	 * Gets the correct error message to be displayed
	 * @param {Object} error - the error
	 * @returns {String} the correct message
	 * */
	static getErrorMessage(error) {
		if (error.response) {
			if (error.response.data === '') {
				return error.response.statusText;
			} else if (error.response.data.errorMessage) {
				return error.response.data.errorMessage;
			}
		} else if (error.message && error.message !== '') {
			return error.message;
		} else {
			return error.response.message;
		}
	}

	/**
	 * Creates a rich text element to show rich text. Eg. (Bold, Italic, Underline...)
	 * rastwe: TODO: need some justification for having this here. Kendo editor?
	 * @param {string} text - the text to display
	 * @returns {Object} a rich text element to display
	 * */
	/*static createRichTextElement(text) {
		return <span dangerouslySetInnerHTML={Env.createMarkup(text)} />;
	}*/

	/**
	 * Private function to return html required for setting dangerous inner html
	 * @param {string} text - the text to display
	 * @returns {Object} an object with html set to the text passed in
	 * */
	static createMarkup(text) {
		return { __html: text };
	}

	/**
	 * Removes all html tags. Similar to getting innerText
	 * @param {string} text - the text to cleanup
	 * @returns {string} the cleaned up text
	 * */
	static removeAllHTMLTags(text) {
		return text.replace(/<(?:.|\n)*?>/gm, ' ');
	}

	/**
	 * Private function to return if its a training environment
	 * @returns {boolean} the flag to show/hide training badge
	 * */
	static getIsTraining() {
		return envConfig.isTrainingEnvironment;
	}

	static getArchiveRetryShortcutKey() {
		if (envConfig.archiveRetryShortcutKey) {
			return envConfig.archiveRetryShortcutKey;
		} else {
			return 'A,T,L';
		}
	}

	static getEYEmplADStatusExtension() {
		return envConfig.eyEmplADStatusExtension;
	}

	/**
	 * Get flag to control Portfolio tab showing or not
	 * @return {boolean} flag to show/hide portfolio tab
	 */
	static getPortfolioCfg() {
		return envConfig.showPortfolioTab;
	}

	/**
	 * Get url for atlas web
	 * @return {string} url to atlas web
	 */
	static getHelpWebUrl() {
		return envConfig.helpWeb;
	}

	/**
	 * Get flag to control russia language showing or not
	 * @return {boolean} flag to show/hide russia language
	 */
	static getRussiaLanguageEnabled() {
		return envConfig.russiaEnabled ?? false;
	}

	/**
	 * Get doc arc documentation URL
	 * @return {string} doc arc documentation url
	 */
	static getDocArcDocumentationUrl() {
		return envConfig.docArcDocumentation ?? '';
	}

	static getFederatedHostName() {
		return FEDERATED_HOST_NAME;
	}

	static getAdObjectConfiguration() {
		return envConfig.ad;
	}
}

export default Env;
